import { Userpilot } from "userpilot";
import pubsub from "@signicat/pubsub";
import { mapEnvironmentToApi } from "@signicat/signicat-mfes-config-mapping";
import { environmentKey } from "./globalVariables.js";

function addUserpilot(userpilotId) {
  if (!userpilotId) {
    return;
  }

  Userpilot.initialize(userpilotId, {
    "endpoint": mapEnvironmentToApi().replace(/^https:\/\//, '') + "/userpilot-proxy",
    "domain": mapEnvironmentToApi().replace(/^https:\/\//, '') + "/userpilot-proxy"
  });
  pubsub.emitLatestEvent("userInfo", identifyUser);
  pubsub.subscribe("userInfo", identifyUser);
  pubsub.subscribe("accessToken", identifyUser);
}

function identifyUser() {
  if (Userpilot.loaded()) {
    const userInfo = pubsub.lastValue("userInfo");
    if (!userInfo.email || !userPilotEnabled(userInfo.email)) {
      return
    }
    const userId = getUserId(userInfo);
    if (userId) {
      console.log("user pilot user identified as", userId);
      Userpilot.identify(userId);
    }
  }
}

function getUserId(userInfo) {
  return userInfo.idp_id ? userInfo.idp_id : parseUserIdFromToken(pubsub.lastValue("accessToken"));
}

function parseUserIdFromToken(token) {
  if (!token) {
    return null;
  }
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));

    const payload = JSON.parse(jsonPayload);

    return payload.idp_id;
  } catch (e) {
    console.warn("Error decoding JWT:", e);
    return null;
  }
}

window.addEventListener("popstate", () => {
  if (Userpilot.loaded()) {
    const userInfo = pubsub.lastValue("userInfo");
    if (!userInfo.email || !userPilotEnabled(userInfo.email)) {
      return
    }
    
    Userpilot.reload();
    console.log("user pilot is reloaded");
  }
});

function userPilotEnabled(email) {
  const devEmailList = [
    "rhyslo@signicat.com",
    "andrei.iftimie@signicat.com",
    "mihmit@signicat.com",
    "silneg@signicat.com",
    "donata.kersyte@signicat.com",
    "ninmat@signicat.com",
    "dragos.gherghina@signicat.com",
    "nicoleta.fusea@signicat.com",
    "bruno.martins@signicat.com",
    "bognoa@signicat.com",
    "ionturcanuv@gmail.com"
  ];

  const prodEmailList = [
    "rhys.sloman-brown@signicat.com",
    "donker@signicat.com",
    "ninmat@signicat.com",
    "bruno.martins@signicat.com",
    "ion.turcanu@signicat.com"
  ];

  const e2eEmailPatterns = [
    "dtpe2e+.*?@gmail.com",
    "e2e.ciam.testuser+.*?@gmail.com"
  ]

  const regexPatterns = e2eEmailPatterns.map(pattern => new RegExp(`^${pattern}$`));
  const environment = sessionStorage.getItem(environmentKey);
  const normalizedEmail = email.toLowerCase();

  if(regexPatterns.some(regex => regex.test(normalizedEmail))) {
    return false;
  }

  if (environment === "dev") {
    return devEmailList.includes(normalizedEmail);
  }

  if (environment === "prod" && normalizedEmail.endsWith('@signicat.com')) {
    return prodEmailList.includes(normalizedEmail);
  }

  return true;
}

export { addUserpilot, identifyUser };
