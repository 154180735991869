function addAnalytics(document, gaTag) {

    // Defensive approach: don't set anything if `gaTag` is not present nor defined
    // in the configuration (that is the root-config.json file).
    if (!gaTag) {
        return
    }

    const scriptElem = document.createElement("script");
    scriptElem.setAttribute("async", "true");
    scriptElem.setAttribute("src", `https://www.googletagmanager.com/gtag/js?id=${gaTag}`);
    document.head.appendChild(scriptElem);

    scriptElem.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", gaTag);
    };

    let script1Elem = document.createElement("script");
    script1Elem.setAttribute("src", "https://static.signicat.com/assets/legal/openli_widget.js");

    document.head.insertBefore(script1Elem, document.head.firstChild);
}

export { addAnalytics };
